<template>
  <div class="container" v-cloak>
    <!-- 您的贷款申请已提交 -->
    <div class="top-con u-flex-col u-col-center">
      <img src="../assets/images/icon_check_markMax2.png" />
      <h4>您的贷款申请已提交</h4>
      <p>贷款金额：{{ loanAmount }}元</p>
      <span class="u-text-center">
        我们为您推出了专属企业微信客服，随时为您解决贷款问题，请及时联系您的专属客服以协助您完成贷款
      </span>
    </div>

    <!-- 极融花专属客服 -->
    <div class="exclusive-customer-service">
      <div class="u-flex">
        <img src="../assets/images/logo.png" />
        <div class="u-flex-1">
          <h5>极融花专属客服</h5>
          <p>我们将7*24小时为您服务</p>
        </div>
      </div>
      <div class="btn u-text-center" @click="handleOk">好的</div>
    </div>

    <!-- 步骤 -->
    <div class="steps-con">
      <h4>贷款流程</h4>
      <ul class="u-flex u-text-center">
        <li class="loan-process-li u-flex-1">
          <img src="../assets/images/icon_fkxtsh.png" />
          <h6>风控系统审核</h6>
        </li>
        <li class="line pr">
          <img class="pa" src="../assets/images/tl4_step_line.png" />
        </li>
        <li class="loan-process-li u-flex-1">
          <img src="../assets/images/icon_dhfs.png" />
          <h6>联系专属客服</h6>
        </li>
        <li class="line pr">
          <img class="pa" src="../assets/images/tl4_step_line.png" />
        </li>
        <li class="loan-process-li u-flex-1">
          <img src="../assets/images/icon_appqk.png" />
          <h6>等待取款</h6>
        </li>
      </ul>
    </div>

    <!-- 热线电话 -->
    <!-- <p class="tel-con u-text-center">
      <van-icon size="0.22rem" color="#E71A0F" name="phone-o" />
      <a href="tel:4001131190">客服热线: 400-1030-615</a>
    </p>    -->

    <!-- 底部 -->
    <div class="bottom u-text-center">
      服务由深上海赢燚网络科技有限公司提供<br />
      综合年化6%-24%，最终结果以审批为准<br />
      贷款有风险，借款需谨慎<br />
      请根据个人能力合理贷款，理性消费
    </div>

    <!-- 提示浏览器打开遮罩层 -->
    <SharePop :show="showPop" @change="handlePopChange" />
  </div>
</template>

<script>
export default {
  name: "TrialSuccessTl4",
  data() {
    return {
      loanAmount: this.$route.query.loanAmount, // 金额
      workWeixinUrl: this.$route.query.workWeixinUrl, // 跳转地址
      showPop: false, // 分享蒙层状态
    };
  },
  mounted() {
    let isWeixin = this.isWeixin();
    if (isWeixin) {
      this.showPop = true;
    }
  },
  methods: {
    // 好的按钮点击
    handleOk() {
      var timestamp = new Date().getTime();
      window.location.href = this.workWeixinUrl + "?tsp=" + timestamp;
    },

    // 监听提示浏览器打开弹层（我知道了）按钮点击
    handlePopChange(show) {
      this.showPop = show;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #f2f5fa;
  padding: 0 0.4rem 0.8rem;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  .top-con {
    width: 170%;
    padding: 1.22rem calc(35% + 0.15rem) 1.74rem;
    background: #384ed0;
    border-radius: 0 0 60% 60%;
    margin: 0 -37.5%;
    color: #fff;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: normal;

    > img {
      width: 1.2rem;
      height: 1.2rem;
    }

    > h4 {
      font-size: 0.32rem;
      font-family: PingFang SC, PingFang SC-Medium;
      margin: 0.34rem 0 0.16rem;
    }

    > p {
      font-size: 0.28rem;
      padding: 0.1rem 0.16rem;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 3px;
      display: inline-block;
    }

    > span {
      font-size: 0.26rem;
      color: rgba(255, 255, 255, 0.5);
      margin-top: 0.16rem;
    }
  }

  .exclusive-customer-service {
    margin-top: -1.3rem;
    background: #ffffff;
    border-radius: 0.24rem;
    box-shadow: 0 0.1rem 0.16rem 0 rgba(0, 0, 0, 0.06);
    font-size: 0.28rem;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: normal;
    color: #192337;
    padding: 0.3rem;

    img {
      width: 0.84rem;
      height: 0.84rem;
      border-radius: 50%;
      margin-right: 0.2rem;
    }

    h5 {
      margin-bottom: 0.1rem;
    }

    p {
      font-size: 0.24rem;
      font-family: PingFang SC, PingFang SC-Regular;
      color: #868e9e;
    }

    .btn {
      height: 0.72rem;
      line-height: 0.72rem;
      background: #384ed0;
      border-radius: 0.48rem;
      margin-top: 0.36rem;
      color: #ffffff;
    }
  }

  .steps-con {
    margin-top: 0.36rem;
    background: #ffffff;
    border-radius: 0.24rem;
    box-shadow: 0 0.1rem 0.16rem 0 rgba(0, 0, 0, 0.06);
    font-size: 0.28rem;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: normal;
    color: #192337;
    padding: 0.3rem;

    ul {
      align-items: stretch;
      margin-top: 0.28rem;
    }

    .loan-process-li {
      > img {
        width: 0.72rem;
        height: 0.72rem;
        border-radius: 50%;
      }

      h6 {
        font-size: 0.28rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: normal;
        color: #192337;
        margin-top: 0.2rem;
      }
    }

    .line {
      width: 0;
      padding-top: 0.3rem;
      > img {
        width: 0.84rem;
        height: 0.08rem;
        right: -0.4rem;
        top: 0.3rem;
      }
    }
  }

  .bottom {
    margin: 1rem auto 0;
    font-family: PingFangSC, PingFangSC-Regular;
    font-size: 0.22rem;
    color: #c3c2c6;
    line-height: 0.36rem;
    white-space: pre-line;
  }
}
</style>
